<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('LMS')" :isFilter="false" />
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('LMS')" :isFilter="false" />
            </template>
            <div class="mb-5 align-items-center">
                <div class="mb-5">
                  <h5>{{parameter.courseName}} - {{parameter.courseCode}}</h5>
                </div>
                <b-card-group deck>
                    <div v-for="(item, key) in courses" class="col-12 col-sm-6 col-md-6 col-lg-3 mb-4" :key="key">
                        <b-card :bg-variant="item.value===selectedComponent?'primary':'light'"  class="text-center cursor"
                            @click="showDetail(item.value)">
                            <b-card-text>{{ item.text }}</b-card-text>
                        </b-card>
                    </div>
                </b-card-group>
            </div>
            <template>
                <HomeWorkEntryTable v-if="selectedComponent==='homework_entry'" :sectionId="sectionId" :semesterId="semester"/>
            </template>
            <template>
                <AddContentTable v-if="selectedComponent==='add_content'" :sectionId="sectionId" :semesterId="semester"/>
            </template>
            <template>
                <AnnouncementEntryTable v-if="selectedComponent==='announcement_entry'" :sectionId="sectionId" :semesterId="semester"/>
            </template>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout.vue";
import Header from "@/layouts/AppLayout/Header.vue";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile.vue";

// Page
import HomeWorkEntryTable from "@/modules/lms/pages/homework/HomeWorkEntryTable.vue";

// Component
import {EventBus} from "@/main";
import AddContentTable from "@/modules/lms/pages/content/AddContentTable.vue";
import AnnouncementEntryTable from "@/modules/lms/pages/announcement/AnnouncementEntryTable.vue";


// Services

export default {
    components: {
        AnnouncementEntryTable,
        AddContentTable,
        AppLayout,
        Header,
        HeaderMobile,
        HomeWorkEntryTable
    },
    data() {
        return {
            sectionId:null,
            semester:null,
            courses: [
                {
                    text:this.$t('homework_entry'),
                    value:'homework_entry'
                },
                {
                    text:this.$t('add_content'),
                    value:'add_content'
                },
                {
                    text:this.$t('announcement_entry'),
                    value:'announcement_entry'
                }
            ],
            selectedId: null,
            selectedComponent:null,
            filter: {},
            parameter:{}
        }
    },
    metaInfo() {
        return {
            title: this.$t("LMS")
        }
    },
    mounted() {
        EventBus.$on('parameter', (event) => {
            this.parameter=event
            this.sectionId=event.section,
            this.semester=event.semester
        });
    },
    methods: {
        showDetail(value) {
            this.selectedComponent=value
        },

    },
}
</script>
<style scoped>
.cursor {
    cursor: pointer;
}
</style>
