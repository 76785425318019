import Api from '@/services/Index';

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/lms/homeworks', config);
}
const show = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/lms/homeworks/'+id);
}
const update = async (id,formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/lms/homeworks/'+id,formData);
}
const store = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/lms/homeworks', data);
}
const del = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/lms/homeworks/'+id);
}
const downloadFile = async (uuid) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/lms/homeworks/download/'+uuid,{responseType:'arraybuffer'});
}



export default {
    store,
    getAll,
    show,
    update,
    del,
    downloadFile
}
