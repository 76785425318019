
<template>

    <div>
        <div style="display: flex;justify-content: right">
            <b-button variant="outline-primary" @click="addAnnouncementEntry">{{
                    $t('announcement_entry').toUpper()
                }}
            </b-button>
        </div>
        <datatable :title="$t('announcement_entry')" :isLoading.sync="datatable.isLoading" :columns="datatable.columns"
                   :rows="datatable.rows"
                   :total="datatable.total" :queryParams="datatable.queryParams" @on-page-change="onPageChange"
                   @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange"
                   v-show="datatable.showTable">
        </datatable>
        <CommonModal size="xl" ref="announcementEntry" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()">
            <template v-slot:CommonModalTitle>
                <div>{{ $t('announcement_entry').toUpper() }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div>
                    <announcement-entry v-if="process == 'announcement_add'" :sectionId="section"
                                        :semesterId="semester" @addAnnouncementSuccess="addAnnouncementSuccess"></announcement-entry>
                </div>
            </template>
        </CommonModal>
        <CommonModal size="xl" ref="updateAnnouncementEntry" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()">
            <template v-slot:CommonModalTitle>
                <div>{{ $t('update').toUpper() }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div>
                    <UpdateForm v-if="process == 'announcement_update'" :sectionId="section"
                                        :semesterId="semester"  :formId="formId" @updateAnnouncementSuccess="updateAnnouncementSuccess"></UpdateForm>
                </div>
            </template>
        </CommonModal>
    </div>
</template>
<script>
import qs from "qs";

import AnnouncementEntry from "@/modules/lms/pages/announcement/CreateForm.vue";
import LmsAnnouncementsService from "@/services/LmsAnnouncementsService";
import UpdateForm from "@/modules/lms/pages/announcement/UpdateForm.vue";
import LmsContentService from "@/services/LmsContentService";

export default {
    props: {
        sectionId: {},
        semesterId: {}
    },
    components: {UpdateForm, AnnouncementEntry},
    data() {
        return {
            process: null,
            formId:null,
            section: null,
            semester: null,
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('edit'),
                                class: 'ri-search-eye-line align-middle top-minus-1 mr-3 text-muted',
                                permission: "course_update",
                                callback: (row) => {
                                    this.updateFormShow(row.id)
                                }
                            },
                            {
                                text: this.$t('delete'),
                                class: 'ri-delete-bin-7-line align-middle top-minus-1 mr-3 text-muted',
                                permission: "course_delete",
                                callback: (row) => {
                                    this.formDelete(row.id)
                                }
                            }
                        ]
                    },
                    {
                        label: this.$t('id'),
                        field: 'id',
                        hidden: true,
                    },
                    {
                        label: this.$t('course'),
                        field: 'course_id',
                        hidden: false,
                        tdClass: 'text-center',
                        thClass: 'text-center',
                    },
                    {
                        label: this.$t('instructor'),
                        field: 'instructor_id',
                        hidden: false,
                        tdClass: 'text-center',
                        thClass: 'text-center',
                    },
                    {
                        label: this.$t('semester'),
                        field: 'semester_id',
                        hidden: false,
                        tdClass: 'text-center',
                        thClass: 'text-center',
                    },
                    {
                        label: this.$t('content'),
                        field: 'content',
                        hidden: false,
                        tdClass: 'text-center',
                        thClass: 'text-center',
                    },
                    {
                        label: this.$t('start_date'),
                        field: 'from_date',
                        hidden: false,
                        tdClass: 'text-center',
                        thClass: 'text-center',
                    },
                    {
                        label: this.$t('end_date'),
                        field: 'to_date',
                        hidden: false,
                        tdClass: 'text-center',
                        thClass: 'text-center',
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: '-from_date',
                    page: 1,
                    limit: 20
                }
            },
            form: {}
        }
    },
    created() {
        this.selectedIds()
        this.getRows()
    },

    methods: {
      async  selectedIds(){
            this.semester=this.semesterId
            this.section=this.sectionId
        },
        formClear(){
          this.process=null
            this.formId=null
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },
        onShowDetailButton(id) {
            this.$router.push('/job/logs/detail/' + id);
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            return LmsAnnouncementsService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data
                    this.datatable.total = response.data.pagination.total
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        addAnnouncementEntry() {
            this.process = 'announcement_add'
            this.$refs.announcementEntry.$refs.commonModal.show()
        },
        addAnnouncementSuccess(){
            this.process = null
            this.$refs.announcementEntry.$refs.commonModal.hide()
        },
        updateFormShow(id){
            this.process ='announcement_update'
            this.formId=id
            this.$refs.updateAnnouncementEntry.$refs.commonModal.show()

        },
        updateAnnouncementSuccess(){
            this.$refs.updateAnnouncementEntry.$refs.commonModal.hide()
            this.formClear()
            this.getRows()
        },
        formDelete(id) {
            this.$swal
                .fire({
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no')
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        LmsAnnouncementsService.del(id)
                            .then(response => {
                                this.$toast.success(this.$t('api.' + response.data.message));
                                this.getRows();
                                this.formClear();
                            })
                            .catch(error => {
                                this.$toast.error(this.$t('api.' + error.data.message));
                            });
                    }
                })
        },


    },

};
</script>
