
<template>
    <div>
        <ValidationObserver ref="formValidate">
            <div class="row d-flex">
                <!--              <b-col cols="4">-->
                <!--                    <ValidationProvider name="semester" rules="required" v-slot="{ valid, errors }">-->
                <!--                        <b-form-group :label="$t('semester')">-->
                <!--                            <semesters-selectbox v-model="form.semester_id"-->
                <!--                                                 :validate-error="errors[0]"></semesters-selectbox>-->
                <!--                        </b-form-group>-->
                <!--                    </ValidationProvider>-->
                <!--              </b-col>-->

                <!--              <b-col cols="4">-->
                <!--                <b-form-group :label="$t('course')" class="mb-1">-->
                <!--                    <ValidationProvider name="course_id" rules="required"-->
                <!--                                        v-slot="{ errors }">-->
                <!--                        <course-auto-complete v-model="form.course_id"-->
                <!--                                              :validate-error="errors[0]"/>-->
                <!--                    </ValidationProvider>-->
                <!--                </b-form-group>-->
                <!--              </b-col>-->

                <b-col cols="12">
                    <b-form-group :label="$t('teaching_staff')">
                        <ValidationProvider name="instructor_id" rules="required" v-slot="{ errors }">
                            <staff-auto-complete v-model="form.instructor_id"
                                                 :validate-error="errors[0]" />
                        </ValidationProvider>
                    </b-form-group>
                </b-col>


                <b-col cols="4">
                    <ValidationProvider name="from_date" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('start_date')">
                            <b-form-input type="datetime-local" size="sm" v-model="form.from_date"></b-form-input>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <b-col cols="4">
                    <ValidationProvider name="to_date" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('end_date')">
                            <b-form-input type="datetime-local" size="sm" v-model="form.to_date"></b-form-input>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <!--             <b-col cols="4">-->
                <!--                  <b-form-group :label="$t('section')">-->
                <!--                      <b-form-select v-model="form.section_ids"-->
                <!--                                     :options="sectionOptions"/>-->
                <!--                  </b-form-group>-->
                <!--             </b-col>-->
                <b-col cols="12" class="mt-2 mb-2">
                    <ValidationProvider name="content" rules="required" v-slot="{valid, errors}">
                            <ckeditor v-model="form.content"/>
                    </ValidationProvider>

                </b-col>

            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                <b-button variant="primary" @click="save">{{ $t('save').toUpper() }}</b-button>
            </div>

        </ValidationObserver>
    </div>

</template>
<script>

import LmsAnnouncementsService from "@/services/LmsAnnouncementsService";
import moment from "moment/moment";
export default {
    components: {
    },
    props: {
        semesterId:{},
        sectionId:{},
        formId:{
            type:Number
        }
    },
    data() {
        return {
            form:{
                content:null,
                instructor_id:null,
                from_date:null,
                to_date:null,
                section_ids:[]
            }
        }
    },
    created() {
        this.getData()
    },
    methods: {
        getData(){
          LmsAnnouncementsService.show(this.formId)
              .then(response=>{
                let data =response.data.data
                  this.form={
                      content:data.content,
                      course_id:data.course_id,
                      to_date:data.to_date,
                      from_date:data.from_date,
                      instructor_id:data.instructor_id,
                  }
              }).catch(err=>{
                  this.showErrors(err)
          })

        },
        async  save() {
            const isValid = await this.$refs.formValidate.validate();
            if (isValid){

                this.form.semester_id=this.semesterId
                this.form.section_ids=[this.sectionId]

                this.form.to_date = moment(this.form.to_date).format('YYYY-MM-DD HH:mm:ss');
                this.form.from_date = moment(this.form.from_date).format('YYYY-MM-DD HH:mm:ss');
                LmsAnnouncementsService.update(this.formId,this.form)
                    .then(response=>{
                        this.$toast.success(response.data.message)
                        this.$emit('updateAnnouncementSuccess',true)

                    }).catch(err=>{
                    this.showErrors(err)
                })
            }

        }
    },

};
</script>
