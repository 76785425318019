<template>
    <div>
        <div style="display: flex;justify-content: right">
            <b-button variant="outline-primary" @click="homeworkEntry">{{
                    $t('homework_entry').toUpper()
                }}
            </b-button>
        </div>

        <datatable :title="$t('homework_list')" :isLoading.sync="datatable.isLoading" :columns="datatable.columns"
                   :rows="datatable.rows"
                   :total="datatable.total" :queryParams="datatable.queryParams" @on-page-change="onPageChange"
                   @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange"
                   v-show="datatable.showTable">
        </datatable>

        <CommonModal size="xl" ref="homeworkEntryModal" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()">
            <template v-slot:CommonModalTitle>
                <div>{{ $t('homework_entry').toUpper() }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div>
                    <HomeWorkEntryForm v-if="process == 'homework_entry'" :sectionId="section" :semesterId="semester" @addHomeWorkEntrySuccess="addHomeWorkEntrySuccess"></HomeWorkEntryForm>
                </div>
            </template>
        </CommonModal>
        <CommonModal size="xl" ref="updateHomeWorkEntry" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()">
            <template v-slot:CommonModalTitle>
                <div>{{ $t('update').toUpper() }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div>
                    <UpdateForm v-if="process == 'homework_entry_update'" :sectionId="section" :semesterId="semester" :formId="formId" @addHomeWorkEntrySuccess="addHomeWorkEntrySuccess"></UpdateForm>
                </div>
            </template>
        </CommonModal>
    </div>
</template>
<script>
import qs from "qs";
import HomeWorkEntryForm from "@/modules/lms/pages/homework/CreateForm.vue";
import LmsHomeWorkEntryService from "@/services/LmsHomeWorkEntryService";
import LmsAnnouncementsService from "@/services/LmsAnnouncementsService";
import UpdateForm from "@/modules/lms/pages/homework/UpdateForm.vue";

export default {
    components: {
        UpdateForm,
        HomeWorkEntryForm
    },
    props: {
        sectionId: {},
        semesterId: {}
    },
    data() {
        return {
            section: null,
            semester: null,
            process: null,
            formId: null,
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('edit'),
                                class: 'ri-search-eye-line align-middle top-minus-1 mr-3 text-muted',
                                permission: "course_update",
                                callback: (row) => {
                                    this.updateFormShow(row.id)
                                }
                            },
                            {
                                text: this.$t('delete'),
                                class: 'ri-delete-bin-7-line align-middle top-minus-1 mr-3 text-muted',
                                permission: "course_delete",
                                callback: (row) => {
                                    this.formDelete(row.id)
                                }
                            }
                        ]
                    },
                    {
                        label: this.$t('id'),
                        field: 'id',
                        hidden: false,
                    },
                    {
                        label: this.$t('content'),
                        field: 'content',
                        hidden: false,
                        tdClass: 'text-center',
                        thClass: 'text-center',
                    },
                    {
                        label: this.$t('file'),
                        field: 'file.file_name',
                        hidden: false,
                        tdClass: 'text-center',
                        thClass: 'text-center',
                    },
                    {
                        label: this.$t('instructor'),
                        field: 'instructor_id',
                        hidden: false,
                        tdClass: 'text-center',
                        thClass: 'text-center',
                    },
                    {
                        label: this.$t('semester'),
                        field: 'semester_id',
                        hidden: false,
                        tdClass: 'text-center',
                        thClass: 'text-center',
                    },
                    {
                        label: this.$t('from_date'),
                        field: 'from_date',
                        hidden: false,
                        tdClass: 'text-center',
                        thClass: 'text-center',
                    },
                    {
                        label: this.$t('to_date'),
                        field: 'to_date',
                        hidden: false,
                        tdClass: 'text-center',
                        thClass: 'text-center',
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {
                    },
                    sort: '-id',
                    page: 1,
                    limit: 20
                }
            },
            form: {}
        }
    },
    created() {
        this.selectedIds()
        this.getRows()
    },
    methods: {
      async selectedIds(){
            this.section = this.sectionId
            this.semester = this.semesterId
        },
        formClear(){
          this.process=null
          this.formId=null
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },
        onShowDetailButton(id) {
            this.$router.push('/job/logs/detail/' + id);
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            return LmsHomeWorkEntryService.getAll(config)
                .then((response) => {

                    this.datatable.rows = response.data.data
                    this.datatable.total = response.data.pagination.total
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        homeworkEntry() {
            this.process = 'homework_entry'
            this.$refs.homeworkEntryModal.$refs.commonModal.show()
        },
        addHomeWorkEntrySuccess(){
            this.formClear()
            this.$refs.homeworkEntryModal.$refs.commonModal.hide()
            this.getRows()

        },
        updateFormShow(id){
            this.process ='homework_entry_update'
            this.formId=id
            this.$refs.updateHomeWorkEntry.$refs.commonModal.show()

        },
        updateAnnouncementSuccess(){
            this.$refs.updateHomeWorkEntry.$refs.commonModal.hide()
            this.formClear()
            this.getRows()
        },
        formDelete(id) {
            this.$swal
                .fire({
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no')
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        LmsHomeWorkEntryService.del(id)
                            .then(response => {
                                this.$toast.success(this.$t('api.' + response.data.message));
                                this.getRows();
                                this.formClear();
                            })
                            .catch(error => {
                                this.$toast.error(this.$t('api.' + error.data.message));
                            });
                    }
                })
        },
    },

};
</script>
