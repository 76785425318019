<template>
    <div>
        <div style="display: flex;justify-content: right">
            <b-button variant="outline-primary" @click="addContent">{{
                    $t('add_content').toUpper()
                }}
            </b-button>
        </div>
        <datatable :title="$t('add_content')" :isLoading.sync="datatable.isLoading" :columns="datatable.columns"
                   :rows="datatable.rows"
                   :total="datatable.total" :queryParams="datatable.queryParams" @on-page-change="onPageChange"
                   @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange"
                   v-show="datatable.showTable">
        </datatable>
        <CommonModal size="xl" ref="addContent" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()">
            <template v-slot:CommonModalTitle>
                <div>{{ $t('add_content').toUpper() }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div>
                    <add-content v-if="process == 'add_content'" :sectionId="section" :semesterId="semester"
                                 @addContentSuccess="addContentSuccess"></add-content>
                </div>
            </template>
        </CommonModal>
        <CommonModal size="xl" ref="updateContent" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()">
            <template v-slot:CommonModalTitle>
                <div>{{ $t('update').toUpper() }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div>
                    <UpdateForm v-if="process == 'update_content'" :formId="formId"  :sectionId="section" :semesterId="semester"
                                 @updateContentSuccess="updateContentSuccess"></UpdateForm>
                </div>
            </template>
        </CommonModal>
    </div>
</template>
<script>
import qs from "qs";
import AddContent from "@/modules/lms/pages/content/CreateForm.vue";
import LmsContentService from "../../../../services/LmsContentService";
import UpdateForm from "@/modules/lms/pages/content/UpdateForm.vue";

export default {
    components: {
        UpdateForm,
        AddContent
    },
    props: {
        sectionId: {},
        semesterId: {}
    },
    data() {
        return {
            section: null,
            semester: null,
            process: null,
            formId: null,
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('edit'),
                                class: 'ri-search-eye-line align-middle top-minus-1 mr-3 text-muted',
                                permission: "course_update",
                                callback: (row) => {
                                    this.updateFormShow(row.id)
                                }
                            },
                            {
                                text: this.$t('delete'),
                                class: 'ri-delete-bin-7-line align-middle top-minus-1 mr-3 text-muted',
                                permission: "course_delete",
                                callback: (row) => {
                                    this.formDelete(row.id)
                                }
                            }
                        ]
                    },
                    {
                        label: this.$t('id'),
                        field: 'id',
                        hidden: true,
                    },
                    {
                        label: this.$t('course'),
                        field: 'course_id',
                        hidden: false,
                        tdClass: 'text-center',
                        thClass: 'text-center',
                    },
                    {
                        label: this.$t('instructor'),
                        field: 'instructor_id',
                        hidden: false,
                        tdClass: 'text-center',
                        thClass: 'text-center',
                    },
                    {
                        label: this.$t('semester'),
                        field: 'semester_id',
                        hidden: false,
                        tdClass: 'text-center',
                        thClass: 'text-center',
                    },
                    {
                        label: this.$t('title'),
                        field: 'title',
                        hidden: false,
                        tdClass: 'text-center',
                        thClass: 'text-center',
                    },
                    {
                        label: this.$t('start_date'),
                        field: 'from_date',
                        hidden: false,
                        tdClass: 'text-center',
                        thClass: 'text-center',
                    },
                    {
                        label: this.$t('end_date'),
                        field: 'to_date',
                        hidden: false,
                        tdClass: 'text-center',
                        thClass: 'text-center',
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: 'from_date',
                    page: 1,
                    limit: 20
                }
            },
            form: {}
        }
    },
    created() {
        this.section = this.sectionId
        this.semester = this.semesterId
        this.getRows()
    },
    methods: {
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },
        onShowDetailButton(id) {
            this.$router.push('/job/logs/detail/' + id);
        },
        formClear() {
            this.process = null
            this.formId = null
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            return LmsContentService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data
                    this.datatable.total = response.data.pagination.total
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        addContent() {
            this.process = 'add_content'
            this.$refs.addContent.$refs.commonModal.show()

        },
        addContentSuccess() {
            this.$refs.addContent.$refs.commonModal.hide()
            this.getRows()

        },
        updateFormShow(id) {
            this.formId=id
            this.process = 'update_content'
            this.$refs.updateContent.$refs.commonModal.show()

        },
        updateContentSuccess(){
            this.$refs.updateContent.$refs.commonModal.hide()
            this.formClear()
            this.getRows()

        },
        formDelete(id) {
            this.$swal
                .fire({
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no')
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        LmsContentService.del(id)
                            .then(response => {
                                this.$toast.success(this.$t('api.' + response.data.message));
                                this.getRows();
                                this.formClear();
                            })
                            .catch(error => {
                                this.$toast.error(this.$t('api.' + error.data.message));
                            });
                    }
                })
        },

    },
};
</script>
