
<template>
    <div>
        <ValidationObserver ref="formValidate">
            <div class="row d-flex">
                <!--              <b-col cols="4">-->
                <!--                    <ValidationProvider name="semester" rules="required" v-slot="{ valid, errors }">-->
                <!--                        <b-form-group :label="$t('semester')">-->
                <!--                            <semesters-selectbox v-model="form.semester_id"-->
                <!--                                                 :validate-error="errors[0]"></semesters-selectbox>-->
                <!--                        </b-form-group>-->
                <!--                    </ValidationProvider>-->
                <!--              </b-col>-->

                <!--              <b-col cols="4">-->
                <!--                <b-form-group :label="$t('course')" class="mb-1">-->
                <!--                    <ValidationProvider name="course_id" rules="required"-->
                <!--                                        v-slot="{ errors }">-->
                <!--                        <course-auto-complete v-model="form.course_id"-->
                <!--                                              :validate-error="errors[0]"/>-->
                <!--                    </ValidationProvider>-->
                <!--                </b-form-group>-->
                <!--              </b-col>-->

                <b-col cols="12">
                    <b-form-group :label="$t('teaching_staff')">
                        <ValidationProvider name="instructor_id" rules="required" v-slot="{ errors }">
                            <staff-auto-complete v-model="form.instructor_id"
                                                 :validate-error="errors[0]" />
                        </ValidationProvider>
                    </b-form-group>
                </b-col>


                <b-col cols="4">
                    <ValidationProvider name="from_date" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('start_date')">
                            <b-form-input type="datetime-local" size="sm" v-model="form.from_date"></b-form-input>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="4">
                    <ValidationProvider name="to_date" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('end_date')">
                            <b-form-input type="datetime-local" size="sm" v-model="form.due_date"></b-form-input>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <b-col cols="12" class="mt-2 mb-2">
                    <ValidationProvider name="content" rules="required" v-slot="{valid, errors}">
                        <ckeditor v-model="form.explanation"/>
                    </ValidationProvider>

                </b-col>

                <b-col cols="8" class="mb-2 mt-2">
                    <ValidationProvider name="file" :rules="form.file_name?'':'required'" v-slot="{valid, errors}">
                        <b-form-group :label="$t('file')">
                            <div class="d-flex custom-file-upload">
                                <b-form-file v-model="form.file"
                                             :state="errors[0] ? false : (valid ? true : null)"
                                             :placeholder="form.file_name"
                                             :drop-placeholder="$t('drop_file')"
                                             ref="fileInput"
                                             multiple
                                />
                                <b-button variant="outline-secondary"
                                          @click="$refs.fileInput.$el.childNodes[0].click();">{{ $t('browse') }}
                                </b-button>
                            </div>
                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="4" style="display: flex;align-items: center">
                        <b-button squared variant="primary" v-b-tooltip.hover :title="$t('show')"
                                  @click="showFile()">
                            <i class="ri-file-upload-line" style="font-size: 25px"></i>
                        </b-button>
                </b-col>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                <b-button variant="primary" @click="save">{{ $t('save').toUpper() }}</b-button>
            </div>

        </ValidationObserver>
    </div>

</template>
<script>

import moment from "moment/moment";
import LmsHomeWorkEntryService from "@/services/LmsHomeWorkEntryService";
import lmsContentService from "@/services/LmsContentService";
export default {
    components: {
    },
    props: {
        semesterId:{},
        sectionId:{},
        formId:{},
    },
    data() {
        return {
            form:{
                explanation:null,
                file_name:null,
                from_date:null,
                due_date:null,
                instructor_id:null,
                fileData:null
            }
        }
    },
    created() {
        this.getData()
    },
    methods: {
        getData(){
          LmsHomeWorkEntryService.show(this.formId)
              .then(response=>{
                  let data = response.data.data
                  this.form={
                      explanation:data.content,
                      file_name:data.file.file_name,
                      from_date: data.from_date,
                      due_date: data.to_date,
                      instructor_id: data.instructor_id,
                      fileData:data.file

                  }
              }).catch(err=>{
                 this.showErrors(err)
          })
        },
        async  save() {
            const isValid = await this.$refs.formValidate.validate();
            if (isValid){
                this.form.due_date = moment(this.form.due_date).format('YYYY-MM-DD HH:mm:ss');
                this.form.from_date = moment(this.form.from_date).format('YYYY-MM-DD HH:mm:ss');
                let formData = new FormData()
                formData.append('explanation',this.form.explanation)
                formData.append('semester_id',this.semesterId)
                formData.append('course_id',this.$route.params.courseId)
                formData.append('section_ids[]',this.sectionId)
                formData.append('instructor_id',this.form.instructor_id)
                formData.append('from_date',this.form.from_date)
                formData.append('due_date',this.form.due_date)
                if (this.form.file){
                    formData.append('file',this.form.file[0])
                }
                LmsHomeWorkEntryService.store(formData)
                    .then(response=>{
                        this.$toast.success(response.data.message)
                        this.$emit('addHomeWorkEntrySuccess',true)
                    }).catch(err=>{
                    this.showErrors(err)
                })
            }

        },
        showFile(){
            LmsHomeWorkEntryService.downloadFile(this.form.fileData.uuid)
                .then(response=>{
                    this._downloadFile(response,this.form.fileData.file_name)
                }).catch(err=>{
                this.showErrors(err)
            })
        }
    },

};
</script>
