<template>
    <div>
        <ValidationObserver ref="formValidate">
            <div class="row d-flex">
                <!--              <b-col cols="4">-->
                <!--                    <ValidationProvider name="semester" rules="required" v-slot="{ valid, errors }">-->
                <!--                        <b-form-group :label="$t('semester')">-->
                <!--                            <semesters-selectbox v-model="form.semester_id"-->
                <!--                                                 :validate-error="errors[0]"></semesters-selectbox>-->
                <!--                        </b-form-group>-->
                <!--                    </ValidationProvider>-->
                <!--              </b-col>-->

                <!--              <b-col cols="4">-->
                <!--                <b-form-group :label="$t('course')" class="mb-1">-->
                <!--                    <ValidationProvider name="course_id" rules="required"-->
                <!--                                        v-slot="{ errors }">-->
                <!--                        <course-auto-complete v-model="form.course_id"-->
                <!--                                              :validate-error="errors[0]"/>-->
                <!--                    </ValidationProvider>-->
                <!--                </b-form-group>-->
                <!--              </b-col>-->

                <b-col cols="12">
                    <b-form-group :label="$t('teaching_staff')">
                        <ValidationProvider name="instructor_id" rules="required" v-slot="{ errors }">
                            <staff-auto-complete v-model="form.instructor_id"
                                                 :returnType="'id'"
                                                 :validate-error="errors[0]"/>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>

                <b-col cols="12">
                    <ValidationProvider name="title" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('title')">
                            <b-form-input v-model="form.title"></b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                            </b-form-invalid-feedback>
                        </b-form-group>

                    </ValidationProvider>
                </b-col>

                <b-col cols="4">
                    <ValidationProvider name="from_date" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('start_date')">
                            <b-form-input type="datetime-local" size="sm" v-model="form.from_date"></b-form-input>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <b-col cols="4">
                    <ValidationProvider name="to_date" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('end_date')">
                            <b-form-input type="datetime-local" size="sm" v-model="form.to_date"></b-form-input>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <b-col cols="8">
                    <ValidationProvider name="file" :rules="form.file_name==null?'required':''" v-slot="{valid, errors}">
                        <b-form-group :label="$t('file')">
                            <div class="d-flex custom-file-upload">
                                <b-form-file v-model="form.file"
                                             :state="errors[0] ? false : (valid ? true : null)"
                                             :placeholder="form.file_name"
                                             :drop-placeholder="$t('drop_file')"
                                             ref="fileInput"
                                             multiple
                                />
                                <b-button variant="outline-secondary"
                                          @click="$refs.fileInput.$el.childNodes[0].click();">{{ $t('browse') }}
                                </b-button>
                            </div>
                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col class="col-4">
                    <div class="mt-5"
                    >
                        <b-button squared variant="primary" v-b-tooltip.hover :title="$t('show')"
                                  @click="showFile()">
                            <i class="ri-file-upload-line" style="font-size: 25px"></i>
                        </b-button>
                    </div>

                </b-col>

            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                <b-button variant="primary" @click="update">{{ $t('save').toUpper() }}</b-button>
            </div>

        </ValidationObserver>
    </div>

</template>
<script>

import lmsContentService from "@/services/LmsContentService";
import moment from "moment";

export default {
    props: {
        semesterId: {},
        sectionId: {},
        formId: {
            type: Number
        }
    },
    data() {
        return {
            form: {
                course_id:null,
                from_date:null,
                to_date:null,
                title:null,
                instructor_id:null,
                file_name:null,
                section_ids:[],
                fileData:null,
            },
            parameter: {},
        }
    },
    watch: {
        formId(value) {
            this.getData(value)
        }
    },
    created() {
     this.getData(this.formId)
    },
    methods: {
        getData(id){
            lmsContentService.show(id)
                .then(response => {
                    let data =response.data.data
                    this.form ={
                        course_id:data.course_id,
                        from_date:data.from_date,
                        to_date:data.to_date,
                        title:data.title,
                        fileData: data.file,
                        instructor_id:data.instructor_id,
                        file_name: data.file.file_name,
                        section_ids:data.section_ids,
                        semester_id:data.semester_id
                    }

                }).catch(err => {
                this.showErrors(err)
            })
        },
        async update() {
            const isValid = await this.$refs.formValidate.validate();
            if (isValid) {
                this.form.to_date = moment(this.form.to_date).format('YYYY-MM-DD HH:mm:ss');
                this.form.from_date = moment(this.form.from_date).format('YYYY-MM-DD HH:mm:ss');
                let formData = new FormData()
                if (this.form.file){
                    formData.append('file', this.form.file[0])
                }
                formData.append('semester_id', this.semesterId)
                formData.append('course_id', this.$route.params.courseId)
                formData.append('section_ids[]', this.sectionId)
                formData.append('title', this.form.title)
                formData.append('instructor_id', this.form.instructor_id)
                formData.append('to_date', this.form.to_date)
                formData.append('from_date', this.form.from_date)
                lmsContentService.update(this.formId,formData)
                    .then(response => {
                        this.$toast.success(response.data.message)
                        this.$emit('updateContentSuccess', true)

                    }).catch(err => {
                    this.showErrors(err)
                })
            }

        },
        showFile(){
            lmsContentService.downloadFile(this.form.fileData.uuid)
                .then(response=>{
                    this._downloadFile(response,this.form.fileData.file_name)
                }).catch(err=>{
                    this.showErrors(err)
            })

        }
    },

};
</script>
